import React from "react"
import LayoutDefault from "../layouts/LayoutDefault"

const mdxPageLayout = props => {
  return (
    <LayoutDefault {...props}>
      <section className={"hero section"}>
        <div className="container">{props.children}</div>
      </section>
    </LayoutDefault>
  )
}

export default mdxPageLayout
