import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/projects/wizartsoft.com/src/views/mdxPageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`FAQ`}</h2>
    <h5>{`How do I get access?`}</h5>
    <p>{`Unfortunately, at the moment, we give access to the early builds only for selected studios. If you are representing the studio and you are interested, contact us via mail. `}<a href="mailto:hello@wizartsoft.com"><a parentName="p" {...{
          "href": "mailto:hello@wizartsoft.com"
        }}>{`hello@wizartsoft.com`}</a></a></p>
    <h4>{`When will the source code be available on Github?`}</h4>
    <p>{`We are working to make it available to everyone. If you are interested in testing out the software and possibly, speeding up the open-sourcing process, please mail us.`}</p>
    <h4>{`If I am not representing the studio, can I get access?`}</h4>
    <p>{`Unfortunately, at the moment, the answer is no. When the state of the software changes, we will let everyone know.`}</p>
    <h4>{`Was the Wizart DCC Platform used in any productions?`}</h4>
    <p>{`Yes. It was used on animated Netflix's feature `}<a href="https://www.netflix.com/title/81267965">{`Secret Magic Control Agency`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      